import { PanelPlugin } from '@grafana/data';
import { SimplePanel } from './SimplePanel';
import { SimpleOptions } from './app-context/types';
import { getTemplateSrv } from '@grafana/runtime';

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel).setPanelOptions((builder) => {
  const lengthVariable = getTemplateSrv().replace('$length_unit');

  return (
    builder
      // Ambient indicators
      .addBooleanSwitch({
        path: 'showTemperature',
        name: 'Show ambient temperature',
        description: 'Hide/display ambient temperature.',
        defaultValue: true,
        category: ['Ambient indicators'],
      })
      .addBooleanSwitch({
        path: 'showHumidity',
        name: 'Show ambient humidity',
        description: 'Hide/display ambient humidity.',
        defaultValue: false,
        category: ['Ambient indicators'],
      })
      // Vibration Severity options
      .addNumberInput({
        path: 'vibrationLimit',
        name: 'Upper limit',
        description: `Customize the upper Vibration Severity limit (${lengthVariable === 'inch' ? 'in/s' : 'mm/s'}).`,
        defaultValue: lengthVariable === 'inch' ? 1 : 20,
        category: ['Vibration Severity'],
      })
      // Shock indicators options
      .addNumberInput({
        path: 'shockCritical',
        name: 'Critical',
        description: 'Customize the "critical" Shock Indicator threshold.',
        defaultValue: 4,
        category: ['Shock Indicator'],
      })
      .addNumberInput({
        path: 'shockDanger',
        name: 'Danger',
        description: 'Customize the "danger" Shock Indicator threshold.',
        defaultValue: 10,
        category: ['Shock Indicator'],
      })
      // Rotation Speed options
      .addBooleanSwitch({
        path: 'showRpmGauge',
        name: 'Show gauge',
        description: 'Hide/display Rotation Speed gauge. If displayed, it will replace the Shock Indicator.',
        defaultValue: false,
        category: ['Rotation Speed'],
      })
      .addNumberInput({
        path: 'rpmCritical',
        name: 'Critical',
        description: 'Customize the "critical" RPM threshold.',
        defaultValue: 7000,
        category: ['Rotation Speed'],
      })
      .addNumberInput({
        path: 'rpmDanger',
        name: 'Danger',
        description: 'Customize the "danger" RPM threshold.',
        defaultValue: 8500,
        category: ['Rotation Speed'],
      })
      .addNumberInput({
        path: 'rpmLimit',
        name: 'Gauge upper limit',
        description: 'Customize the upper RPM limit.',
        defaultValue: 10000,
        category: ['Rotation Speed'],
      })
      // Incident Advisor options
      .addNumberInput({
        path: 'minPtsTrainingSet',
        name: 'Training set - Minimum points',
        description: 'Minimum of points so that the selected training zone is valid.',
        defaultValue: 500,
        category: ['Incident Advisor'],
      })
      .addNumberInput({
        path: 'maxPtsTrainingSet',
        name: 'Training set - Maximum points',
        description: 'Maximum of points so that the selected training zone is valid.',
        defaultValue: 35000,
        category: ['Incident Advisor'],
      })
      .addNumberInput({
        path: 'anomalyScoreThreshold',
        name: 'Anomaly Score Threshold (in %)',
        description: 'Above this value, anomalies classes and associated texts will be displayed',
        defaultValue: 30,
        category: ['Incident Advisor'],
      })
      .addNumberInput({
        path: 'anomalyClassSimilarityThreshold',
        name: 'Anomaly Class Similarity Threshold (in %)',
        description: 'Above this value, anomalies classes and associated texts will be displayed',
        defaultValue: 5,
        category: ['Incident Advisor'],
      })
  );
});
