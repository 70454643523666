import { useStyles2 } from '@grafana/ui';
import { MIDDLE_GREY } from 'constants/colors';
import { css, keyframes } from '@emotion/css';
import * as React from 'react';

interface LoadingIndicatorsProps {}

/**
 * Placeholder of the instant indicators while loading dictionnary
 */

export const LoadingIndicators: React.FunctionComponent<LoadingIndicatorsProps> = () => {
  const styles = useStyles2(getStyles);

  return (
    <div
      className={css`
        animation: ${styles.element} 2s ease infinite;
      `}
    >
      <div className={styles.wrapper}>
        <div className={styles.headBox}>
          {/* Title and icon that switches from gauge to graph on click */}
          <div className={styles.titleContainer}></div>
          {/* Button to iso table */}
          <div className={styles.isoText}></div>
        </div>
        <div className={styles.plotContainer}></div>
      </div>
    </div>
  );
};

const getStyles = () => {
  const loadingBackground = 'rgba(255, 255, 255, 0.4)';

  return {
    headBox: css`
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      background-color: ${MIDDLE_GREY};
      padding: 9px 12px;
    `,
    plotContainer: css`
      height: 225px;
      min-width: 235px;
      width: 100%;
      background: ${loadingBackground};
      border: 8px solid ${MIDDLE_GREY};
    `,
    isoText: css`
      width: 55px;
      align-self: flex-start;
      background: ${loadingBackground};
      margin-top: 3px;
      height: 17px;
    `,
    wrapper: css`
      width: 100%;
      flex: 1;
    `,
    titleContainer: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 21px;
      background: ${loadingBackground};
    `,
    element: keyframes`
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.4;
        }
        100% {
          opacity: 1;
        } 
        `,
  };
};
