import { Icon, useStyles2, Tooltip } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { css } from '@emotion/css';
import { getAlertColorStatus } from 'helpers/thresholds';
import React, { useEffect, useState } from 'react';

interface AlertIndicatorProps {
  name: string;
  panelId: number;
  state: string;
  url: string;
  linkToAlert: string;
}

interface AlertProps {
  name: string;
}

export const AlertState: React.FunctionComponent<AlertProps> = ({ name }) => {
  const [alertIndicator, setAlertIndicator] = useState({} as AlertIndicatorProps);
  const [alertColor, setColorAlert] = useState('');
  const styles = useStyles2(() => getAlertStyles(alertColor));

  const { state } = useAppState();
  const { user, alerts, dico } = state;

  const goToPanel = user.orgRole !== 'Viewer' ? 'editPanel' : 'viewPanel';

  /**
   * Translation
   */

  const TR_ALERT_STATE = dico['alert-state']?.toUpperCase();
  const TR_EDIT_ALERT_SETTINGS = dico['edit-alert-settings'];

  useEffect(() => {
    if (alerts.length) {
      const alertIndex = alerts.findIndex((alert) => alert.name?.toUpperCase() === name?.toUpperCase());
      if (alertIndex !== -1) {
        const alertFound = alerts[alertIndex];
        const alertProperties = {
          name: alertFound.name,
          panelId: alertFound.panelId,
          state: alertFound.state,
          url: alertFound.url,
          linkToAlert: `${alertFound.url}?tab=alert&${goToPanel}=${alertFound.panelId}`,
        };
        setAlertIndicator(alertProperties);
      }
    }
  }, [alerts]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!alertIndicator) {
      return;
    }
    const alertStatusColor = getAlertColorStatus(alertIndicator.state);
    setColorAlert(alertStatusColor);
  }, [alertIndicator]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {Object.keys(alertIndicator).length !== 0 && (
        <span onClick={() => window.open(alertIndicator.linkToAlert)} className={styles.spanClick}>
          <Tooltip
            interactive
            content={
              <>
                <div style={{ fontSize: '13px' }}>{alertIndicator.name?.toUpperCase()}</div>
                <div className={styles.state}>
                  {TR_ALERT_STATE} : {alertIndicator.state}
                </div>
                <div style={{ cursor: 'pointer' }}>
                  <i>
                    {'>'} {TR_EDIT_ALERT_SETTINGS}
                  </i>
                </div>
              </>
            }
          >
            <Icon name="heart" type="mono" className={styles.alertIcon} />
          </Tooltip>
        </span>
      )}
    </>
  );
};

const getAlertStyles = (alertColor: string) => {
  return {
    alertIcon: css`
      color: ${alertColor};
    `,
    spanClick: css`
      cursor: pointer;
      margin-right: 4px;
    `,
    state: css`
      color: ${alertColor};
      font-size: 13px;
      font-weight: 600;
    `,
  };
};
