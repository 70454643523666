import { AnomalyContentProps, AnomalyProps, IdElementProps } from 'app-context/types';
import { DICTIONARY_URL, STRATEGIES_URL } from 'constants/api';
import { getJson } from 'services/requests';

type StrategyNames = 'strategy-0x101' | 'strategy-0x102' | 'strategy-0x201' | 'strategy-0x202';

export const translateDictionary = async (langVariable: string) => {
  let lang = langVariable as string;

  // Set english by default if no lang
  if (!langVariable) {
    lang = 'EN';
  }
  const url = `${DICTIONARY_URL}${lang}.json`;
  const dictionary = await getJson(url);

  const translatedDictionary = {} as any;
  for (const [key, value] of Object.entries(dictionary)) {
    if (key === 'identity-card') {
      translatedDictionary['identity-card'] = {};
      for (const [idCardKey, idCardValue] of Object.entries(dictionary[key])) {
        translatedDictionary['identity-card'][idCardKey] = idCardValue;
      }
    } else {
      let valueTranslated = value;
      translatedDictionary[key] = valueTranslated;
    }
  }
  return translatedDictionary;
};

/** Get texts of defaults depending on strategy */
export const getStrategyTexts = async (
  lang: string,
  strategy: string | number,
  identityCard: IdElementProps[],
  functionField: string
) => {
  const strategies = await getJson(`${STRATEGIES_URL}${lang}.json`);

  const strategyKey = ('strategy-' + strategy) as StrategyNames;
  const strategyTexts = strategies[strategyKey] as AnomalyContentProps[];

  const processFunctionIndex = identityCard.findIndex((idElement) => idElement.name === functionField);
  let processFunction = '';

  if (processFunctionIndex !== -1) {
    processFunction = identityCard[processFunctionIndex].value?.toLowerCase();
  }

  const filteredStrategyTexts = [] as AnomalyProps[];
  for (const anomaly of strategyTexts) {
    let causes = [] as string[];

    // Function related causes
    anomaly.content.causes.forEach((cause) => {
      if (cause.id === processFunction) {
        causes.push(...cause.texts);
      }
    });

    // General causes
    anomaly.content.causes.forEach((cause) => {
      if (cause.id === 'all') {
        causes.push(...cause.texts);
      }
    });
    const filteredStrategy = { ...anomaly, content: { causes: causes, symptoms: anomaly.content.symptoms } };
    filteredStrategyTexts.push(filteredStrategy);
  }

  return filteredStrategyTexts;
};
