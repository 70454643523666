import * as React from 'react';
import { useStyles2, TabsBar, Tab, TabContent } from '@grafana/ui';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

interface LoadingTabsContainerProps {
  theme: GrafanaTheme2;
}

/**
 * Placeholder of the instant indicators while loading dictionnary
 */

export const LoadingTabsContainer: React.FunctionComponent<LoadingTabsContainerProps> = ({ theme }) => {
  /**
   * Translation
   */

  const TR_INCIDENT_ADVISOR = '';
  const TR_TRAINING_ZONES_MGMT = '';
  const TR_TRAINING_HISTORY = '';
  const TR_SETTINGS = '';

  const tabs = [
    { label: TR_INCIDENT_ADVISOR, active: true },
    { label: TR_TRAINING_ZONES_MGMT, active: false },
    { label: TR_TRAINING_HISTORY, active: false },
    { label: TR_SETTINGS, active: false },
  ];
  const styles = useStyles2(getStyles);

  return (
    <>
      <TabsBar>
        {tabs.map((tab, index) => {
          return (
            <Tab
              key={`${tab.label}-${index}`}
              label={tab.label}
              active={tab.active}
              style={{
                backgroundColor: `${tab.active ? (theme.isDark ? '#222426' : '#fbfbfb') : 'transparent'}`,
                fontSize: 'calc(13px + 0.2vw)',
                fontWeight: 600,
                color: `${tab.active ? theme.colors.text.primary : theme.colors.text.secondary}`,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '200px',
              }}
            />
          );
        })}
      </TabsBar>
      <TabContent className={styles.tabContent}>{tabs[0].active && <div></div>}</TabContent>
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    tabContent: css`
      height: 100%;
      min-height: 514px;
      width: 100%;
      padding: 20px;
      background-color: ${theme.isDark ? '#222426' : '#fbfbfb'};
    `,
    tab: css`
      background-color: ${theme.isDark ? '#222426' : '#fbfbfb'};
      height: 100%;
    `,
  };
};
