import { UserSelectionProps, SelectedZone, ShapeProps, FinalZoneProps, ZoneProps } from 'app-context/types';
import { GraphSeriesValue, dateTimeFormatISO, DataFrame, GrafanaTheme2, TimeRange } from '@grafana/data';
import {
  GAUGE_THRESHOLDS_COLORS,
  USER_SELECTION,
  MIDDLE_GREY,
  COLOR_OPEN,
  COLOR_CLOSED,
  COLOR_SILENT,
} from 'constants/colors';

// HELPERS RELATED TO PLOTLY GRAPH AND DATA VIZ ELEMENTS

/** Creation of $interval parameter based on pixels size of element */
export const getGroupByTimeParameter = (from: number, to: number) => {
  const deltaT = to - from;
  const nPixels = document.getElementById('plot-div')?.offsetWidth as number;
  const groupSize = Math.ceil(deltaT / (nPixels * 1000 * 60));
  return groupSize;
};

export const applyThemeToGraph = (
  layout: {
    xaxis: { color: string; gridcolor: string };
    yaxis: { color: string; gridcolor: string; zerolinecolor?: string };
    title?: { font: { color: string } };
  },
  theme: GrafanaTheme2
) => {
  layout.xaxis.color = theme.isDark ? '#b3b3b3' : MIDDLE_GREY;
  layout.xaxis.gridcolor = theme.isDark ? '#424345' : '#ececec';
  layout.yaxis.color = theme.isDark ? '#b3b3b3' : MIDDLE_GREY;
  layout.yaxis.gridcolor = theme.isDark ? '#424345' : '#ececec';
  if (layout.title) {
    layout.title.font.color = theme.colors.text.primary;
  }
  if (layout.yaxis.hasOwnProperty('zerolinecolor')) {
    layout.yaxis.zerolinecolor = theme.isDark ? '#424345' : '#ececec';
  }
};

// FORMAT PANEL DATA

/** Retrieve ALL values by alias query and build plotly data object to display graph */
export const getData = (data: DataFrame[], alias: string, timeZone: string) => {
  const dataIndex = data.findIndex((d) => d.name?.toUpperCase() === alias?.toUpperCase());
  if (dataIndex === -1) {
    return;
  }

  const values = data[dataIndex];
  const xVals: GraphSeriesValue[] = values?.fields[0].values;
  const yVals: GraphSeriesValue[] = values?.fields[1].values;
  const formatedDates = xVals?.map((d: any) => dateTimeFormatISO(d, { timeZone: timeZone }));

  return {
    x: formatedDates,
    y: yVals?.map((val) => val?.toFixed(2)),
    type: 'scatter',
    mode: 'lines+markers',
    line: {
      width: 1,
      color: '',
      dash: '',
    },
    marker: {
      size: 1,
      color: [] as string[] | string,
    },
    fill: '',
    fillcolor: '',
    xaxis: 'x',
    yaxis: 'y',
    name: alias,
    hovertemplate: '',
  };
};

export const getOpenClosedData = (data: DataFrame[], alias: string, timeZone: string) => {
  if (!data?.length) {
    return [];
  }
  const dataIndex = data.findIndex((d) => d.name?.toUpperCase() === alias?.toUpperCase());
  if (dataIndex === -1) {
    return [];
  }

  const values = data[dataIndex];
  const xVals: number[] = values?.fields[0].values;
  const yVals: number[] = values?.fields[1].values;
  const formatedDates = xVals?.map((d: any) => dateTimeFormatISO(d, { timeZone: timeZone }));

  if (!xVals.length || !yVals.length) {
    return [];
  }

  const colorscaleValue = [
    [0, COLOR_CLOSED],
    [0.5, COLOR_OPEN],
    [1, COLOR_SILENT],
  ];

  const temp = {
    x: formatedDates,
    y: [] as number[],
    z: [] as number[],
    zmin: 0,
    zmax: 1,
    zsmooth: 'false',
    yaxis: 'y2',
    type: 'heatmap',
    colorscale: colorscaleValue,
    showscale: false,
    hoverinfo: 'skip',
  };

  for (let i = 0; i < xVals.length; i++) {
    if (!yVals?.length) {
      continue;
    }
    temp.x.push(formatedDates[i]);
    temp.y.push(0);
    temp.z.push(yVals[i] > 0.5 ? 0.5 : yVals[i] < -0.5 ? 1 : 0);
  }
  return [temp];
};

export const getDataFromQuery = (data: any[]) => {
  if (!data) {
    return;
  }

  const xVals: GraphSeriesValue[] = data.map((d) => d[0]);
  const yVals: GraphSeriesValue[] = data.map((d) => d[1]);
  // const formatedDates = xVals?.map((d: any) => dateTimeFormatISO(d));

  return {
    x: xVals,
    y: yVals?.map((val) => val?.toFixed(2)),
    type: 'scatter',
    mode: 'lines+markers',
    line: {
      width: 1,
      color: '#848484',
    },
    marker: {
      size: 1,
    },
    fill: '',
    xaxis: 'x',
    yaxis: 'y',
    name: 'Selected Zones',
  };
};

// Graphs - Instant indicators (vibration severity, rpm, shock indicator)

export const getGraphConfig = (lang: string, isSelectable: boolean) => {
  const buttonsToRemove = ['toImage', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'resetScale2d', 'pan2d'];
  if (!isSelectable) {
    buttonsToRemove.push('select2d');
  }
  return {
    locale: lang?.toLowerCase(),
    displayModeBar: true,
    displaylogo: false,
    responsive: true,
    modeBarButtonsToRemove: buttonsToRemove,
  };
};

// TRAININGS ZONES MGMT

/** Add shape on plotly graph (zone selection) */
export const addShape = (userSelection: UserSelectionProps, zone: SelectedZone) => {
  return {
    type: 'rect',
    layer: 'below',
    xref: 'x',
    yref: 'paper',
    x0: userSelection.from,
    y0: 0,
    x1: userSelection.to,
    y1: 1,
    fillcolor: zone.fillcolor,
    opacity: 0.1,
    line: { width: 0 },
    captureevents: true,
    id: zone.id,
    zonePoints: userSelection.points,
  };
};

export const updateShape = (shape: ShapeProps, final: ZoneProps) => {
  shape.x0 = final.from;
  shape.x1 = final.to;
  shape.zonePoints = final.zonePoints;
  return shape;
};

export const removeDuplicatedPoints = (array1: number[], array2: number[]) => {
  const uniquePoints = [...new Set([...array1, ...array2])];
  return uniquePoints;
};

export const updateZone = (shape: ShapeProps, final: FinalZoneProps, selectedPoints: number[]) => {
  final.id = shape.id;
  final.totalPoints = removeDuplicatedPoints(selectedPoints, final.zonePoints);
  final.bgColor = shape.fillcolor;
  return final;
};

export const updateUserSelection = (
  zoneParams: {
    shape: ShapeProps;
    final: FinalZoneProps;
    userPoints: number[];
    selectedPoints: number[];
  },
  from: string,
  to: string
) => {
  zoneParams.final.from = from;
  zoneParams.final.to = to;
  zoneParams.final.zonePoints = removeDuplicatedPoints(zoneParams.shape.zonePoints, zoneParams.userPoints);
  updateShape(zoneParams.shape, zoneParams.final);
  updateZone(zoneParams.shape, zoneParams.final, zoneParams.selectedPoints);
  return zoneParams.final;
};

/** Set timerange of grafana time picker to the plotly graph when no data */

export const applyDefaultTimeRange = (
  graphLayout: { xaxis: { autorange: boolean; range: string[] }; uirevision: any },
  timeRange: TimeRange
) => {
  const rand = () => Math.random();

  graphLayout.xaxis.range = [dateTimeFormatISO(timeRange.from.format()), dateTimeFormatISO(timeRange.to.format())];
  graphLayout.xaxis.autorange = false;
  graphLayout.uirevision = rand();
};

/** Markers are colored depending on gauge thresholds. */

export const getDataMarkers = (data: DataFrame[], alias: string, thresholdsGauge: any[], timeZone: string) => {
  if (!data.length) {
    return;
  }
  const dataIndex = data.findIndex((d) => d.name?.toUpperCase() === alias?.toUpperCase());
  if (dataIndex === -1) {
    return;
  }
  const values = data[dataIndex];
  const xVals: GraphSeriesValue[] = values?.fields[0].values;
  const yVals: GraphSeriesValue[] = values?.fields[1].values;
  const formatedDates = xVals?.map((d: any) => dateTimeFormatISO(d, { timeZone: timeZone }));
  const yValues = yVals?.map((val) => val?.toFixed(2));
  const markersColors = yValues.map((y: any) => {
    let color = '';
    switch (true) {
      case y <= thresholdsGauge[0]:
        color = GAUGE_THRESHOLDS_COLORS[0];
        break;
      case y >= thresholdsGauge[0] && y <= thresholdsGauge[1]:
        color = GAUGE_THRESHOLDS_COLORS[1];
        break;
      case y >= thresholdsGauge[1]:
        color = GAUGE_THRESHOLDS_COLORS[2];
        break;
      default:
        color = 'gray';
        break;
    }
    return color;
  });

  return [
    {
      x: formatedDates,
      y: yValues,
      mode: 'markers',
      marker: { color: markersColors, size: 4 },
      xaxis: 'x',
      yaxis: 'y',
      name: alias,
    },
  ];
};

// ON / OFF THRESHOLD - Ultrasound histogram

/** Vertical red line that shows on/off threshold position */
export const updateVerticalCursor = (inputValue: number) => {
  return [
    {
      type: 'line',
      x0: inputValue,
      y0: 0,
      x1: inputValue,
      y1: 0.95,
      xref: 'x',
      yref: 'paper',

      line: {
        color: 'red',
        width: 2,
      },
    },
  ];
};

// ANOMALY SCORE HISTORY GRAPH

/** On user graph click, display an annotation (date of the clicked point) */
export const getAnnotation = (e: { points: any[] }) => ({
  xanchor: 'right',
  xref: 'x',
  yref: 'y',
  x: e.points[0].x,
  y: 110,
  text: ` ${e.points[0].x} `,
  bgcolor: `${USER_SELECTION}`,
  showarrow: false,
  font: {
    size: 11,
    color: '#ffffff',
  },
  captureevents: true,
});

export const getVerticalDottedLine = (e: { points: any[] }) => ({
  type: 'line',
  x0: e.points[0].x,
  y0: 0,
  x1: e.points[0].x,
  y1: 100,
  line: {
    color: `${USER_SELECTION}`,
    width: 2,
    dash: 'dot',
  },
});

// Background of the vertical line
export const getBackgroundLine = (e: { points: any[] }) => ({
  type: 'line',
  x0: e.points[0].x,
  y0: 0,
  x1: e.points[0].x,
  y1: 110,
  line: {
    color: `white`,
    width: 3,
    dash: 'solid',
  },
});

/**  Display a cross to remove vertical line */
export const getCloseAnnotation = (e: { points: any[] }) => ({
  xanchor: 'left',
  xref: 'x',
  yref: 'y',
  x: e.points[0].x,
  y: 110,
  text: ` X `,
  bgcolor: `${USER_SELECTION}`,
  showarrow: false,
  font: {
    size: 10,
    color: '#ffffff',
    fontWeight: 900,
  },
  captureevents: true,
});

export const removeVerticalLine = (layout: { shapes: any[]; annotations: any[] }) => {
  layout.shapes = [];
  layout.annotations = [];
};

export const getUserClickPointIndex = (points: any[], curveNumber: number) =>
  points.findIndex((point: any) => point.curveNumber === curveNumber);

export const getUserClick = (e: { points: any[] }) => {
  const backgroundLine = getBackgroundLine(e);
  const verticalDottedLine = getVerticalDottedLine(e);
  const annotationDate = getAnnotation(e);
  const closeAnnotation = getCloseAnnotation(e);
  return {
    backgroundLine: backgroundLine,
    verticalLine: verticalDottedLine,
    date: annotationDate,
    close: closeAnnotation,
  };
};

export const getScoreDataCustomStyle = (
  data: {
    line: { color: string };
    marker: { color: string | string[] };
    mode: string;
    type: string;
    hovertemplate: string;
  },
  defaultColor: string,
  currentValue: string
) => {
  data.line.color = defaultColor;
  data.marker.color = defaultColor;
  data.mode = 'markers';
  data.type = 'bar';
  data.hovertemplate = `${currentValue}: %{y:.0f}%<extra></extra> `;
};

export const getTrendScoreDataCustomStyle = (
  data: { mode: string; line: { color: string; width: number }; hovertemplate: string },
  lineColor: string,
  trend: string
) => {
  data.mode = 'lines';
  data.line.color = lineColor;
  data.line.width = 2;
  data.hovertemplate = `${trend}: %{y:.0f}%<extra></extra> `;
};

export const addTopMarginToGraph = (layout: { yaxis: { range: number[] } }, array: any[]) => {
  const numberFormat = array
    .filter((value: string) => value) // remove undefined otherwise Math.max returns NaN
    .map((value: string) => parseFloat(value));
  const maxValue = Math.max(...numberFormat);
  layout.yaxis.range = [0, maxValue + (maxValue * 20) / 100]; // add margin to the top of the graph
};
