import { useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { css } from '@emotion/css';
import React from 'react';

interface IndicatorValueProps {
  value: number | string;
  unit: string;
}

export const IndicatorValue: React.FunctionComponent<IndicatorValueProps> = ({ value, unit }) => {
  const { state } = useAppState();
  const { dico } = state;
  const styles = useStyles2(getStyles);

  const TR_NO_DATA = dico['no-data'];

  return (
    <>
      {value !== TR_NO_DATA ? (
        <div className={styles.value}>
          {value} <span className={styles.unit}>{unit}</span>
        </div>
      ) : (
        <div className={styles.noData}>{value}</div>
      )}
    </>
  );
};

const getStyles = () => {
  return {
    value: css`
      font-size: 14px;
      font-weight: 500;
    `,
    unit: css`
      font-size: 13px;
    `,
    noData: css`
      font-size: 11px;
    `,
  };
};
