import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Icon, useStyles2, Tooltip } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { useAppState } from 'app-context/AppStateContext';
import { DrawerIsoTable, GaugeIndicator } from './';
import { AlertState, TitleLight } from 'components/shared/';
import { MIDDLE_GREY, PRIMARY_COLOR_TEXT } from 'constants/colors';
import { getDataMarkers, applyDefaultTimeRange, addTopMarginToGraph } from 'helpers/graphs';
import { getClassRanges } from 'helpers/thresholds';
// import InstantIndicatorGraph from './InstantIndicatorGraph';

interface InstantIndicatorProps {
  decimals: number;
  layout: any;
  max: number;
  thresholds: number[];
  title: string;
  unit: string;
}

const InstantIndicatorGraph = lazy(() => import('./InstantIndicatorGraph'));

/**
 * DIsplays Vibratory severity and RPM data (gauge and graph).
 * Left side panel.
 */

export const InstantIndicator: React.FunctionComponent<InstantIndicatorProps> = ({
  decimals,
  layout,
  max,
  thresholds,
  title,
  unit,
}) => {
  const { state } = useAppState();
  const {
    panelData,
    country,
    identityCard,
    dico,
    panelOptions,
    timeRangePanel,
    lengthUnit,
    isoClassifications,
    timeZone,
  } = state;
  const [isGraphVisible, setIsGraphVisible] = useState(false);
  const [thresholdsGauge, setThresholdsGauge] = useState([] as any);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const styles = useStyles2(getStyles);

  /** Translation */
  const TR_VIBRATORY_SEVERITY = dico['vibration-severity']?.toUpperCase();
  const TR_SHOCK_INDICATOR = dico['shock-indicator']?.toUpperCase();
  const TR_SHOCK_INDICATOR_INFO = dico['shock-indicator-info'];
  const TR_ISO_DRAWER_TITLE = dico['iso-drawer-title'];

  const isVibratoryIndicator = title?.toUpperCase() === TR_VIBRATORY_SEVERITY;
  const isShockIndicator = title?.toUpperCase() === TR_SHOCK_INDICATOR;

  const getPointsArray = () => {
    const instantIndicatorData = getDataMarkers(panelData, title, thresholdsGauge, timeZone) as any;
    layout.xaxis.autorange = true;

    if (!instantIndicatorData) {
      applyDefaultTimeRange(layout, timeRangePanel);
      setGraphData([]);
      return;
    }

    setGraphData(instantIndicatorData);
    addTopMarginToGraph(layout, instantIndicatorData[0].y);
  };

  const switchGraph = () => {
    setIsGraphVisible(!isGraphVisible);
  };

  useEffect(() => {
    if (!panelData.length || !thresholdsGauge.length) {
      applyDefaultTimeRange(layout, timeRangePanel);
      setGraphData([]);
      return;
    }
    if (isGraphVisible) {
      getPointsArray();
    }
  }, [panelData, thresholdsGauge, isGraphVisible, timeRangePanel]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (identityCard.length) {
      if (isVibratoryIndicator) {
        // Apply ISO thresholds
        let classRanges = getClassRanges(identityCard, dico, lengthUnit, isoClassifications);
        setThresholdsGauge([classRanges.alarm, classRanges.trip]);
        return;
      }
      setThresholdsGauge(thresholds);
    }
  }, [identityCard, panelData, panelOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.wrapper}>
      <div className={styles.headBox}>
        {/* Title and icon that switches from gauge to graph on click */}
        <div className={styles.titleContainer}>
          <div style={{ display: 'flex' }}>
            <AlertState name={title} />
            {isShockIndicator && (
              <Tooltip content={TR_SHOCK_INDICATOR_INFO} placement={'bottom-start'}>
                <Icon name={'info-circle'} size={'lg'} style={{ marginRight: '6px' }} />
              </Tooltip>
            )}
            <TitleLight value={title?.toUpperCase()} />
          </div>
          <div className={styles.iconButtonContainer} onClick={switchGraph}>
            {isGraphVisible ? <Icon name="signin" /> : <Icon name="chart-line" size="lg" />}
          </div>
        </div>
        {/* Button to iso table */}
        {isVibratoryIndicator && (
          <div className={styles.isoText} onClick={() => setDrawerIsOpen(true)}>
            ISO CLASSIFICATIONS
          </div>
        )}
      </div>
      <div className={styles.plotContainer}>
        {isGraphVisible ? (
          <Suspense fallback={'Loading...'}>
            <InstantIndicatorGraph title={title} layout={layout} country={country} graphData={graphData} />
          </Suspense>
        ) : (
          <GaugeIndicator decimals={decimals} gaugeThresholds={thresholdsGauge} max={max} title={title} unit={unit} />
        )}
      </div>
      {/* Displays iso table */}
      {drawerIsOpen && <DrawerIsoTable setDrawerIsOpen={setDrawerIsOpen} title={TR_ISO_DRAWER_TITLE} />}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    headBox: css`
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      background: ${theme.isDark ? '#b3b3b3' : MIDDLE_GREY};
      padding: 9px 12px;
      color: ${theme.isDark ? '#141414' : '#d8d9da'};
    `,
    plotContainer: css`
      height: 225px;
      min-width: 235px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: flex-start;
      background: white;
      border: 8px solid ${theme.isDark ? '#b3b3b3' : MIDDLE_GREY};
    `,
    isoText: css`
      font-size: 10px;
      color: ${PRIMARY_COLOR_TEXT};
      cursor: pointer;
      align-self: flex-start;
      background: rgba(255, 255, 255, 0);
      padding: 1px 6px;
      border-radius: 3px;
      border: 1px solid rgba(255, 255, 255, 0.726);
      &:hover {
        background: rgba(255, 255, 255, 0.726);
        color: ${MIDDLE_GREY};
      }
    `,
    wrapper: css`
      width: 100%;
      flex: 1;
    `,
    titleContainer: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    `,
    iconButtonContainer: css`
      color: ${theme.isDark ? '#141414' : '#d8d9da'};
      cursor: pointer;
      background: rgba(255, 255, 255, 0);
      padding: 0px 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      &:hover {
        background: rgba(255, 255, 255, 0.726);
        color: ${MIDDLE_GREY};
      }
    `,
  };
};
