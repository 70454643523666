export enum ThresholdsAnomalyScore {
  BAD = 75,
  MEDIUM = 25,
}

/** DYNAMIC ICONS' THRESHOLDS */

// values in Volt
export enum ThresholdsBattery {
  GOOD = 3.1,
  MEDIUM = 2.8,
  LOW = 2.5,
  VERYLOW = 1.8,
}

// values in dBm
export enum ThresholdsRadio {
  GOOD = -90,
  MEDIUM = -105,
  LOW = -110,
}
