import React from 'react';
import { PanelProps } from '@grafana/data';
import { SimpleOptions } from './app-context/types';
import { MainContainer } from './MainContainer';
import { AppStateProvider } from './app-context/AppStateContext';

interface Props extends PanelProps<SimpleOptions> {}

export const SimplePanel: React.FC<Props> = ({ options, data, timeRange, timeZone }) => {
  return (
    <AppStateProvider>
      <MainContainer data={data} timeRange={timeRange} options={options} timeZone={timeZone} />
    </AppStateProvider>
  );
};
