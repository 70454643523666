import { GrafanaTheme2 } from '@grafana/data';
import { Badge, useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { IdCardProps } from 'app-context/types';
import { VERY_LIGHT_GREY } from 'constants/colors';
import { css, keyframes } from '@emotion/css';
import React, { useEffect, useState } from 'react';

export interface IdentityCardInfosProps {}

export const IdentityCard: React.FunctionComponent<IdentityCardInfosProps> = () => {
  const { state } = useAppState();
  const { macAddress, dashboard, identityCard, isDicoLoading } = state;
  const { name, tags } = dashboard;
  const styles = useStyles2(getStyles);

  return (
    <>
      <div className={styles.topPart}>
        <SectionTitle macAddress={macAddress} title={name} isDicoLoading={isDicoLoading} />
        <Tags tags={tags} isDicoLoading={isDicoLoading} />
      </div>
      <div className={styles.idCard}>
        <IdCard identityCard={identityCard} />
      </div>
    </>
  );
};

/** Dashboard title */

interface SectionTitleProps {
  macAddress: string;
  title: string;
  isDicoLoading: boolean;
}

const SectionTitle: React.FunctionComponent<SectionTitleProps> = ({ macAddress, title, isDicoLoading }) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.containerTitle}>
      {isDicoLoading ? (
        <div
          className={css`
            animation: ${styles.element} 2s ease infinite;
            ${styles.containerTitle}
          `}
        >
          <div className={styles.titleLoading} />
          <div className={styles.titleLoading} />
        </div>
      ) : (
        <>
          <div className={styles.title}>{title}</div>
          <div className={styles.macAddress}>{macAddress?.toUpperCase()}</div>
        </>
      )}
    </div>
  );
};

/** Dashboard Tags */

interface TagsProps {
  tags: string[];
  isDicoLoading: boolean;
}

const Tags: React.FunctionComponent<TagsProps> = ({ tags, isDicoLoading }) => {
  const { state } = useAppState();
  const { dico } = state;
  const styles = useStyles2(getStyles);
  const [machineTag, setMachineTag] = useState<string[]>([]);
  const [additionalTags, setAdditionalTags] = useState<string[]>([]);

  const getMachinetTag = () => {
    const tagFiltered = tags?.filter((tag) => tag.toLowerCase() !== 'machine');
    for (const tag of tagFiltered) {
      if (tag.includes('mac_')) {
        const splitTag = tag.split('_');
        setMachineTag([splitTag[1]]);
      }
    }
  };

  const getAdditionalTags = () => {
    const newTags = tags?.filter((tag) => tag.toLowerCase() !== 'machine');
    const otherTags = [];
    for (const tag of newTags) {
      if (!tag.includes('mac_')) {
        otherTags.push(tag);
      }
    }
    setAdditionalTags(otherTags);
  };

  useEffect(() => {
    if (tags.length) {
      getMachinetTag();
      getAdditionalTags();
    }
  }, [tags]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isDicoLoading ? (
        <div
          className={css`
            animation: ${styles.element} 2s ease infinite;
          `}
        >
          <div className={styles.tagLoading} />
        </div>
      ) : (
        <div className={styles.containerTags}>
          {machineTag?.map((machine, index) => (
            <div className={styles.tag} key={`${machine}-${index}`}>
              <Badge text={machine} color="purple" icon={'cog'} tooltip={dico.machine} />
            </div>
          ))}
          {additionalTags?.map((otherTag, index) => (
            <div className={styles.tag} key={`${otherTag}-${index}`}>
              <Badge text={otherTag} color="blue" icon={'tag-alt'} tooltip={dico.tag} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

/**
 * Fields of the identity card
 */

const IdCard: React.FunctionComponent<IdCardProps> = ({ identityCard }) => {
  const styles = useStyles2(getStyles);

  return (
    <>
      {identityCard
        .filter((id) => id.name !== 'customIso')
        .map((id, index) => {
          return (
            <div className={styles.boxContent} key={`${id?.value}-${index}`}>
              <div className={styles.boxTitle}>{id?.name?.toLowerCase()}</div>
              <div className={styles.idInfo}>{id?.value?.toUpperCase()}</div>
            </div>
          );
        })}
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    containerTitle: css`
      display: flex;
      flex-direction: row;
      align-items: center;
    `,
    containerTags: css`
      display: flex;
      flex-direction: row;
      margin-bottom: 6px;
    `,
    tag: css`
      margin-right: 6px;
      background-color: white;
      border-radius: 2px;
      > div:nth-child(1) {
        padding: 3px 4px;
      }
    `,
    tagLoading: css`
      width: 56px;
      height: 19px;
      background: ${VERY_LIGHT_GREY};
      margin-top: 3px;
      opacity: 0.7;
    `,
    idCard: css`
      height: 60%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-start;
    `,
    title: css`
      font-size: 27px;
      color: ${theme.isDark ? '#292a2d' : '#e9edf2'};
      margin-right: 10px;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 500px;
    `,
    titleLoading: css`
      height: 35px;
      width: 271px;
      background: ${VERY_LIGHT_GREY};
      margin-right: 10px;
      opacity: 0.7;
    `,
    boxContent: css`
      padding: 2px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    `,
    boxTitle: css`
      font-size: 12px;
      margin-right: 3px;
      margin-bottom: -3px;
      color: ${theme.isDark ? '#424345' : '#d8d9da'};
    `,
    macAddress: css`
      color: ${theme.isDark ? '#1f1f20' : '#d8d9da'};
      font-size: 15px;
      font-weight: 500;
    `,
    topPart: css`
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    `,
    idInfo: css`
      font-size: 13px;
      color: rgb(102 112 126);
      font-weight: 500;
      color: ${theme.isDark ? '#222426' : '#f4f5f8'};
    `,
    element: keyframes`
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.4;
      }
      100% {
        opacity: 1;
      } 
      `,
  };
};
