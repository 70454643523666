import React from 'react';
import { useAppState } from 'app-context/AppStateContext';
import { getClosedStatistic } from 'helpers/panel-data';
import { Select, useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';
import { getTemplateSrv } from '@grafana/runtime';

interface ValveStatisticsProps {}

export const ValveStatistics: React.FunctionComponent<ValveStatisticsProps> = () => {
  const { state } = useAppState();
  const { datasourceInflux, statisticsQuery, dico } = state;
  const styles = useStyles2(getStyles);

  const [interval, setInterval] = React.useState('86400000');
  const [statValue, setStatValue] = React.useState('');

  const STATISTICS_OPTIONS = [
    { label: dico['last-24h'], value: '86400000' },
    { label: dico['last-week'], value: '604800000' },
    { label: dico['last-month'], value: '2629800000' },
    { label: dico['last-6months'], value: '15778800000' },
  ];

  const getStatisticValue = async () => {
    if (datasourceInflux?.id && statisticsQuery) {
      const replaceSession = getTemplateSrv().replace('$session_id');
      const replaceVarQuery = statisticsQuery.replace('$session_id', replaceSession);

      const results = await getClosedStatistic(datasourceInflux, replaceVarQuery, interval);
      if (!results?.length) {
        setStatValue('--');
        return;
      }
      if (results[0]?.values?.length) {
        const stat = 100 - results[0]?.values[0][1];
        setStatValue(`${stat.toFixed(1)}%`);
        return;
      }
    }
    setStatValue('--');
  };

  React.useEffect(() => {
    const fetchData = async () => {
      await getStatisticValue();
    };

    fetchData().catch(console.error);
  }, [datasourceInflux, interval]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.wrapper}>
      <div className={styles.sectionTitle}>{dico['closed-state-statistics']?.toUpperCase()}</div>
      <div className={styles.statValue}>{statValue}</div>
      <div style={{ width: '100%' }}>
        <Select options={STATISTICS_OPTIONS} onChange={(e) => setInterval(e.value!)} value={interval} />
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    `,
    sectionTitle: css`
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 8px;
      color: ${theme.colors.text.primary};
    `,
    statValue: css`
      font-size: 32px;
      font-weight: bolder;
      color: ${theme.colors.text.secondary};
    `,
  };
};
