import { Icon, useStyles2 } from '@grafana/ui';
import React from 'react';
import { css } from '@emotion/css';
import { useAppState } from 'app-context/AppStateContext';

interface ValveExplanationsProps {}

export const ValveExplanations: React.FunctionComponent<ValveExplanationsProps> = () => {
  const { state } = useAppState();
  const { dico } = state;

  const styles = useStyles2(getStyles);

  return (
    <div className={styles.mainContainer}>
      <h5 className={styles.title}>
        <Icon name={'chart-line'} /> {dico['valve-explanation-title-1'].toUpperCase()}
      </h5>
      <p className={styles.paragraphShift}>{dico['valve-explanation-text-1']}</p>
      <h5 className={styles.title}>
        <Icon name={'bell'} /> {dico['valve-explanation-title-2'].toUpperCase()}
      </h5>
      <p className={styles.paragraphShift}>
        {dico['valve-explanation-text-2']}
        {/* You can adjust this duration in the alert rules panel based on your specific process
          requirements. */}
      </p>
      <h5 className={styles.title}>
        <Icon name={'exclamation-triangle'} /> {dico['valve-explanation-title-3'].toUpperCase()}
      </h5>
      <p>{dico['valve-explanation-text-3']}</p>
    </div>
  );
};

const getStyles = () => {
  return {
    mainContainer: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      padding: 20px;
      max-height: 390px;
      overflow: auto;
    `,
    title: css`
      font-weight: 500;
    `,
    paragraphShift: css`
      margin-bottom: 20px;
    `,
  };
};
