import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Tooltip } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { MAIN_BORDER_RADIUS } from 'constants/colors';
import { css } from '@emotion/css';
import { getValue } from 'helpers/panel-data';
import React from 'react';
import { IndicatorValue } from './';

const HumidityIcon = require('../../img/humidity.svg') as string;
const TemperatureIcon = require('../../img/temperature.svg') as string;

interface IndicatorsSecondaryProps {}

/**
 * AMBIENT TEMPERATURE AND HUMIDITY INDICATORS.
 * Display icon, value and indicator name on hover.
 */

export const IndicatorsSecondary: React.FunctionComponent<IndicatorsSecondaryProps> = () => {
  const { state } = useAppState();
  const { panelData, panelOptions, dico, temperatureUnit } = state;
  const { showHumidity, showTemperature } = panelOptions;

  const styles = useStyles2(getStyles);

  /**
   * Translation
   */
  const TR_AMBIANT_HUMIDITY = dico['ambiant-humidity'];
  const TR_AMBIANT_TEMPERATURE = dico['ambiant-temperature'];
  const TR_NO_DATA = dico['no-data'];

  const secondaryIndicators = [
    {
      name: TR_AMBIANT_HUMIDITY,
      unit: '%H',
      value: getValue(TR_AMBIANT_HUMIDITY, panelData, TR_NO_DATA, 0),
      icon: HumidityIcon,
      show: showHumidity,
    },
    {
      name: TR_AMBIANT_TEMPERATURE,
      unit: temperatureUnit === 'celsius' ? '°C' : '°F',
      value: getValue(TR_AMBIANT_TEMPERATURE, panelData, TR_NO_DATA, 0),
      icon: TemperatureIcon,
      show: showTemperature,
    },
  ];

  return (
    <>
      {secondaryIndicators.map(
        (indicator, index) =>
          indicator.show && (
            <Tooltip content={indicator?.name?.toUpperCase()} key={`${indicator.name}-${index}`}>
              <div className={styles.communPropBox}>
                <img src={indicator.icon} height={30} />
                <IndicatorValue value={indicator.value} unit={indicator.unit} />
              </div>
            </Tooltip>
          )
      )}
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    communPropBox: css`
      border-radius: ${MAIN_BORDER_RADIUS}px;
      border: 1px solid ${theme.isDark ? theme.colors.border.weak : theme.colors.border.medium};
      margin: 5px;
      padding: 8px;
      display: flex;
      flex: 1;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
    `,
  };
};
