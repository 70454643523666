/**
 * Retrieve LAST value by alias query.
 * Return string if no value is found.
 */

import { TranslationProps } from 'translation';
import { DataFrame, dateTimeFormat } from '@grafana/data';
import { influxRequest } from '../services/requests';
import { COLOR_CLOSED, COLOR_OPEN, COLOR_SILENT } from 'constants/colors';
import { AnomalyProps } from 'app-context/types';

const ValveOpen = require('img/open_valve.svg') as string;
const ValveClosed = require('img/close_valve.svg') as string;

export const getValue = (alias: string, data: any, noData: string | number, decimals: number): string | number => {
  // As Strategy is retreived by an influx tag, it appears in panelData in the second array with alias "Strategy"
  if (alias === 'Strategy') {
    const strategy = data.filter((d: { name: string }) => d.name?.toUpperCase() === alias?.toUpperCase());
    if (!strategy.length || !strategy[1]?.length) {
      return noData;
    }
    return strategy[1]?.fields[1]?.values[strategy[1].fields[1]?.values?.length - 1];
  }

  const aliasIndex = data.findIndex((d: { name: string }) => d.name?.toUpperCase() === alias?.toUpperCase());
  if (aliasIndex !== undefined && aliasIndex !== -1) {
    const value: number = data[aliasIndex].fields[1]?.values[data[aliasIndex].fields[1]?.values?.length - 1];
    if (!value) {
      return 0;
    }
    return value?.toFixed(decimals);
  }
  return noData; // string : "No data" translation
};

export const getSimilaritiesData = (strategyTexts: AnomalyProps[], panelData: DataFrame[]) => {
  const anomaliesData = strategyTexts?.map((anomaly) => {
    const result = getValue(anomaly.queryName, panelData, '0', 0) as string;
    /** Set all negative values to 0 */
    if (parseInt(result, 10) < 0) {
      return 0;
    }
    return parseInt(result, 10);
  });
  return anomaliesData;
};

export const getLastKnownState = (data: DataFrame[], dico: TranslationProps) => {
  if (!data.length) {
    return { state: '--', stateTime: '', color: '', img: '' };
  }
  const dataIndex = data.findIndex((d) => d.name?.toUpperCase() === dico['open-closed-state']?.toUpperCase());

  if (dataIndex === -1) {
    return { state: '--', stateTime: '', color: '', img: '' };
  }

  const OPEN = dico.open?.toUpperCase();
  const CLOSED = dico.closed?.toUpperCase();
  const SILENT = dico.silent?.toUpperCase();

  const values = data[dataIndex];
  const stateTime = values?.fields[0].values[values?.fields[0].values.length - 1];
  const lastState = values?.fields[1].values[values?.fields[0].values.length - 1];
  // const state = lastState >= 0.5 ? OPEN : CLOSED;

  let state = '';

  switch (lastState) {
    case 0:
      state = CLOSED;
      break;
    case 1:
      state = OPEN;
      break;
    case -1:
      state = SILENT;
      break;

    default:
      break;
  }

  let color = '';
  let img = '';

  switch (state) {
    case OPEN:
      color = COLOR_OPEN;
      img = ValveOpen;
      break;
    case CLOSED:
      color = COLOR_CLOSED;
      img = ValveClosed;
      break;
    case SILENT:
      color = COLOR_SILENT;
      img = '';
      break;
    default:
      break;
  }
  return { state, stateTime: dateTimeFormat(stateTime), color, img };
};

export const getClosedStatistic = (
  datasource: { name: string; id: number },
  statisticsQuery: string,
  interval: string
) => {
  const now = Date.now();
  const timeRange = now - Number(interval);

  const query = statisticsQuery.replace('$timeFilter', `time < ${now}ms AND time > ${timeRange}ms`);

  const statisticsData = influxRequest(datasource, query);
  return statisticsData;
};

export const getLastClosedState = (data: DataFrame[], alias: string) => {
  if (!data.length) {
    return { state: 'no data', stateTime: '' };
  }
  const dataIndex = data.findIndex((d) => d.name?.toUpperCase() === alias?.toUpperCase());

  if (dataIndex === -1) {
    return { state: 'no data', stateTime: '' };
  }

  const values = data[dataIndex];

  for (let i = values?.fields[1].values.length + 1; i > 0; i--) {
    if (values?.fields[1].values[i] < 0.5) {
      return { state: values?.fields[1].values[i], stateTime: dateTimeFormat(values?.fields[0].values[i]) };
    }
    continue;
  }

  return { state: 'no data', stateTime: '' };
};
