import { MIDDLE_GREY, PRIMARY_COLOR_TEXT } from 'constants/colors';

export let anomalyScoreGraph = {
  height: 180,
  paper_bgcolor: 'transparent',
  plot_bgcolor: 'transparent',
  margin: {
    l: 25,
    b: 40,
    t: 30,
    r: 50,
  },
  xaxis: {
    type: 'date',
    gridcolor: '',
    autorange: true,
    color: '',
    zeroline: false,
    fixedrange: true, // disables zoom
    ticks: 'outside',
    tickcolor: MIDDLE_GREY,
    tickfont: {
      size: 11,
    },
    range: [],
  },
  yaxis: {
    autorange: false,
    range: [0, 110],
    color: '',
    fixedrange: true, // disables zoom
    zerolinecolor: PRIMARY_COLOR_TEXT,
    gridcolor: '',
  },
  shapes: [] as any,
  annotations: [] as any,
  uirevision: 'true' as any,
  hovermode: 'x unified',
  hoverlabel: {
    bgcolor: 'white',
    bordercolor: 'white',
  },
  showlegend: false,
  title: {
    font: { color: '' },
  },
};
