import React from 'react';
import { useAppState } from 'app-context/AppStateContext';
// @ts-ignore
// Could not find a declaration file for module 'plotly.js-cartesian-dist'.
import Plotly from 'plotly.js-cartesian-dist';
// @ts-ignore
// Could not find a declaration file for module 'react-plotly.js'.
import * as csDictionary from 'plotly.js/lib/locales/fr.js';
// @ts-ignore
// Could not find a declaration file for module 'react-plotly.js/factory'.
import createPlotlyComponent from 'react-plotly.js/factory';
import { acousticEnergyLayout } from 'layouts/acousticEnergyGraph';
import { applyDefaultTimeRange, getData, getOpenClosedData } from 'helpers/graphs';
import { COLOR_CLOSED, COLOR_OPEN, COLOR_SILENT } from 'constants/colors';

Plotly.register(csDictionary);
const ValvePlots = createPlotlyComponent(Plotly);

interface ValveGraphProps {}

export const ValveGraph: React.FunctionComponent<ValveGraphProps> = () => {
  const { state } = useAppState();
  const { panelData, country, timeRangePanel, dico, timeZone } = state;

  // To do
  // SystemJS.load('app/core/app_events').then((appEvents: any) => {
  //   appEvents.on('graph-hover', (e: any) => {
  //     // console.log(Date.parse(dateTimeFormat(e.pos.x)));

  //     Plotly.Fx.hover('plotContainer',
  //       { xval: e.pos.x }
  //       , ['xy']);
  //   });
  // });

  const getGraphData = React.useCallback(() => {
    if (panelData.length !== 0) {
      let acousticDataMin = getData(panelData, 'Acoustic Profile Min', timeZone);
      let acousticDataMax = getData(panelData, 'Acoustic Profile Max', timeZone);
      let openClosedStateData = getOpenClosedData(panelData, dico['open-closed-state'], timeZone);

      if (acousticDataMin?.x?.length) {
        // acousticDataMin.fill = 'tonexty';
        acousticDataMin.line.color = '#81818e';
        acousticDataMin.mode = 'lines';
      }

      if (acousticDataMax?.x?.length) {
        acousticDataMax!.fill = 'tonexty';
        acousticDataMax.fillcolor = '#cccccc91';
        acousticDataMax.line!.color = '#81818e';
        acousticDataMax!.marker.color = '';
        acousticDataMax!.mode = 'lines';
      }

      return [acousticDataMin, acousticDataMax, ...openClosedStateData];
    }
    return [];
  }, [panelData]); // eslint-disable-line react-hooks/exhaustive-deps

  const dataToRender = React.useMemo(() => getGraphData(), [getData, panelData, timeRangePanel]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useLayoutEffect(() => {
    acousticEnergyLayout.xaxis.autorange = true;
    if (!dataToRender.length) {
      applyDefaultTimeRange(acousticEnergyLayout, timeRangePanel);
      return;
    }
  }, [dataToRender]); // eslint-disable-line react-hooks/exhaustive-deps

  const HEATMAP_LEGEND = [
    { name: dico.closed?.toUpperCase(), color: COLOR_CLOSED },
    { name: dico.open?.toUpperCase(), color: COLOR_OPEN },
    { name: dico.silent?.toUpperCase(), color: COLOR_SILENT },
  ];

  return (
    <>
      <ValvePlots
        id="Acoustic profile"
        divId="plotContainer"
        data={dataToRender}
        layout={acousticEnergyLayout}
        style={{ height: '210px' }}
        config={{
          displaylogo: false,
          responsive: true,
          locale: country.toLowerCase(),
          modeBarButtonsToRemove: ['select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'toImage'],
        }}
      />
      {dataToRender.length !== 0 && (
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', padding: '4px' }}>
          {HEATMAP_LEGEND.map((element) => (
            <>
              <div style={{ height: '10px', width: '20px', backgroundColor: element.color, marginRight: '6px' }}></div>
              <div style={{ marginRight: '12px', fontSize: '11px' }}>{element.name}</div>
            </>
          ))}
        </div>
      )}
    </>
  );
};
