import React from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { Icon, useStyles2, Tooltip } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { css } from '@emotion/css';
import { ValveGraph, ValveLastKnownState, ValveStatistics, ValveExplanations, ValveAlert, ValveSession } from './';

interface ValveContainerProps {}

export const ValveContainer: React.FunctionComponent<ValveContainerProps> = () => {
  const { state } = useAppState();
  const { theme, dico } = state;

  const styles = useStyles2(getStyles);

  const acousticProfileInfo = () => {
    return (
      <div>
        <p>{dico['tooltip-graph-text-1']}</p>
        <p>{dico['tooltip-graph-text-2']} </p>
        <p style={{ marginBottom: '20px' }}>{dico['tooltip-graph-text-3']} </p>
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div style={{ width: '100%', height: '100%', display: 'flex' }}>
        {/* data and info container */}
        <div style={{ flex: '3' }}>
          <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <div style={{ flex: '1', display: 'flex' }}>
              <div className={styles.frame} style={{ flex: '6' }}>
                <ValveAlert />
              </div>
              <div className={styles.frame} style={{ flex: '2' }}>
                <ValveLastKnownState />
              </div>
              <div className={styles.frame} style={{ flex: '2' }}>
                <ValveStatistics />
              </div>
            </div>
            <div className={styles.frame} style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
              <div className={styles.graphTitleContainer}>
                <div style={{ marginRight: '6px' }}>{dico['acoustic-profile']?.toUpperCase()}</div>
                <Tooltip content={acousticProfileInfo()}>
                  <Icon name={'info-circle'} />
                </Tooltip>
              </div>
              <ValveGraph />
            </div>
          </div>
        </div>
        {/* Explanations text container */}
        <div className={styles.explanationsContainer}>
          <div className={styles.frameSession}>
            <ValveSession />
          </div>
          <div
            className={styles.frameSession}
            style={{
              flex: '1',
              backgroundColor: theme.isDark ? '#343436' : '#e3e3e385',
            }}
          >
            <ValveExplanations />
          </div>
        </div>
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      width: 100%;
      background-color: ${theme.isDark ? '#222426' : '#fbfbfb'};
      height: 100%;
      display: flex;
      flex-direction: column;
    `,
    frame: css`
      border: 1px solid ${theme.colors.border.medium};
      background-color: ${theme.colors.background.canvas};
      display: flex;
      margin: 5px;
      padding: 12px;
      border-radius: 3px;
    `,
    frameSession: css`
      display: flex;
      margin: 5px;
      border-radius: 3px;
    `,
    explanationsContainer: css`
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
    `,
    graphTitleContainer: css`
      font-size: 16px;
      font-weight: 600;
      display: flex;
    `,
  };
};
