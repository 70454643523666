import React from 'react';
import { useAppState } from '../../../app-context/AppStateContext';
import { css } from '@emotion/css';
import { useStyles2, Tooltip } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { getColorSessionStatus } from 'helpers/thresholds';

const defaultSvg = (
  <svg height="20" width="20">
    <circle cx="10" cy="10" r="10" fill={'gray'} />
  </svg>
);

interface ValveSessionProps {}

export const ValveSession: React.FunctionComponent<ValveSessionProps> = () => {
  const { state } = useAppState();
  const { selectedSession, currentSession, dico } = state;
  const [svgStatus, setSvgStatus] = React.useState(defaultSvg);

  const styles = useStyles2(getStyles);
  const TR_LAST = dico?.last?.toUpperCase();

  const getSvgStatus = (status: string) => {
    const fillColor = getColorSessionStatus(status);
    return (
      <Tooltip content={`Status : ${status}`}>
        <svg height="20" width="20">
          <circle cx="10" cy="10" r="10" fill={fillColor} />
        </svg>
      </Tooltip>
    );
  };

  const getSessionStatus = async () => {
    const svg = getSvgStatus(selectedSession.status);
    setSvgStatus(svg);
  };

  React.useEffect(() => {
    if (selectedSession?.status) {
      getSessionStatus();
    }
  }, [selectedSession, currentSession]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.flexContent} style={{ width: '100%' }}>
      <div className={styles.sessionLabel}>
        {currentSession.id !== 0 && selectedSession.id !== 0 ? (
          <>
            <div>
              SESSION {selectedSession?.uiId} {selectedSession.isLast ? `(${TR_LAST})` : ''}
            </div>
            <div className={styles.svgContainer}>{svgStatus}</div>
          </>
        ) : (
          dico['no-sessions']?.toUpperCase()
        )}
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    flexContent: css`
      display: flex;
      width: 100%;
    `,
    sessionLabel: css`
      background-color: ${theme.isDark ? '#343436' : '#e3e3e385'};
      padding: 8px;
      border-radius: 4px;
      font-size: 14px;
      color: ${theme.colors.text.primary};
      font-weight: 500;
      width: 100%;
      display: flex;
      justify-content: space-between;
    `,
    svgContainer: css`
      display: flex;
      align-items: center;
    `,
  };
};
