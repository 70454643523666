export enum ThresholdColors {
  OK = '#009966',
  MEDIUM = '#FA9D53',
  DANGER = '#FD7B63',
  NONE = '#646464',
}

export const USER_SELECTION = '#608d91';
export const TREND_COLOR_LINE = ThresholdColors.DANGER;

export const COLOR_OPEN = '#4269A9';
export const COLOR_CLOSED = '#ABABAB';
export const COLOR_SILENT = '#EEC559';

export const SELECTED_POLAR_SECTION_COLOR = '#8AB7BB';
export const COLOR_ZONES = [SELECTED_POLAR_SECTION_COLOR, '#EEC559'];
export const PRIMARY_COLOR_TEXT = '#e1e1e1'; // light grey
export const VERY_LIGHT_GREY = '#f8f8f8'; // almost white grey
export const MIDDLE_GREY = '#646464';
export const MAIN_BORDER_RADIUS = 4;

export const GAUGE_THRESHOLDS_COLORS = [ThresholdColors.OK, ThresholdColors.MEDIUM, ThresholdColors.DANGER];

export const POLAR_COLOR = [
  '#212529B3',
  '#343A40B3',
  '#495057B3',
  '#6C757DB3',
  '#ADB5BDB3',
  '#CED4DAB3',
  '#DEE2E6B3',
  '#d3dee9B3',
];
