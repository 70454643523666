import { Drawer } from '@grafana/ui';
import * as React from 'react';

const isoPhoto10816 = require('../../img/iso10816.jpg') as string;
const isoPhoto10816_7 = require('../../img/iso-10816-7.png') as string;
const isoPhoto20816 = require('../../img/iso20816.png') as string;

interface DrawerIsoTableProps {
  setDrawerIsOpen: (value: React.SetStateAction<boolean>) => void;
  title: string;
}

export const DrawerIsoTable: React.FunctionComponent<DrawerIsoTableProps> = ({ setDrawerIsOpen, title }) => {
  return (
    <Drawer
      title={title}
      width={550}
      scrollableContent
      expandable
      onClose={() => {
        setDrawerIsOpen(false);
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <h2>ISO 20816</h2>
        <div style={{ padding: '10px' }}>
          <img src={isoPhoto20816} width={'100%'} style={{ marginBottom: '10px' }} loading="lazy" />
        </div>
        <h2>ISO 10816-7</h2>
        <div style={{ padding: '10px' }}>
          <img src={isoPhoto10816_7} width={'100%'} style={{ marginBottom: '10px' }} loading="lazy" />
        </div>
        <h2>ISO 10816</h2>
        <div style={{ padding: '10px' }}>
          <img src={isoPhoto10816} width={'100%'} style={{ border: '1px solid #494949' }} loading="lazy" />
        </div>
      </div>
    </Drawer>
  );
};
