import { TranslationProps } from 'translation';
import { locationService } from '@grafana/runtime';

export const INCIDENT_ADVISOR_PLUGIN_NAME = 'asystom-ad-ia';

/** Retrieve UID of dashboard from browser window url */
export const getDashboardUid = () => {
  const url = window.location.href.split('/');
  const uidIndex = url.indexOf('d');
  const uid = url[uidIndex + 1];
  return uid;
};

interface IdentityCardProps {
  name: string;
  value: string;
}

export const getIdentityCardOrder = (dico: TranslationProps) => {
  const orderedInfos = [
    { table: 'process_function', translation: dico['process-function'] },
    { table: 'type', translation: dico['machine-part'] },
    { table: 'class', translation: dico.class },
    { table: 'sensor_location', translation: dico['sensor-location'] },
    { table: 'model', translation: dico.model },
    { table: 'manufacturer', translation: dico.manufacturer },
    { table: 'sensor_orientation', translation: dico.orientation },
    { table: 'analysis_profile', translation: dico['analysis-profile'] },
    { table: 'customIso', translation: 'customIso' },
  ];
  return orderedInfos;
};

export const findIndexByPanelType = (type: string, panelsArray: any[]) => {
  const index = panelsArray.findIndex((panel: { type: string }) => panel.type === type);
  return index;
};

export const findIndexByPanelAlias = (alias: string, panelsArray: any[]) => {
  const index = panelsArray.findIndex(
    (panel: { alias: string }) => panel.alias?.toUpperCase() === alias?.toUpperCase()
  );
  return index;
};

export const getOrderedIdCardElements = (dashboard: { panels: any[] }, dico: TranslationProps) => {
  const excludedFields = 'mac_address' || 'tags' || 'machine_name';
  const identityCardInfos = [] as IdentityCardProps[];

  const incidentAdvisorPanelIndex = findIndexByPanelType(INCIDENT_ADVISOR_PLUGIN_NAME, dashboard.panels);
  if (incidentAdvisorPanelIndex === -1) {
    return;
  }

  // Retrieve identity_card elements from Incident Advisor panel
  const idCardElements = dashboard?.panels[incidentAdvisorPanelIndex].idCardElements;
  if (!idCardElements.length) {
    return;
  }

  // Filter elements that will be displayed separately (excludedFields)
  idCardElements.map((idElement: any) => {
    if (idElement.field !== excludedFields) {
      let value = idElement.value;
      if (value === '') {
        value = '-';
      }
      identityCardInfos.push({ name: idElement.field, value: value });
    }
  });

  const identityCardOrder = getIdentityCardOrder(dico);
  // Order id card elements and translation
  const orderedCardInfos = [] as any;

  identityCardOrder.map((orderedElement) => {
    const infoIndex = identityCardInfos.findIndex(
      (idCardInfo: { name: string }) => idCardInfo.name === orderedElement.table
    );

    if (infoIndex !== -1) {
      let idValue = identityCardInfos[infoIndex].value;
      orderedCardInfos.push({ name: orderedElement.translation, value: idValue });
    }
  });
  return orderedCardInfos;
};

export const updateSessionVariable = (sessionId: number) => {
  /** Workaround to update template variable $session_id and refresh dashboard */
  locationService.partial({ 'var-session_id': sessionId }, true);
};
