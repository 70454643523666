import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { AlertState } from 'components/shared/';
import { MAIN_BORDER_RADIUS } from 'constants/colors';
import { css } from '@emotion/css';
import { getValue } from 'helpers/panel-data';
import React, { useEffect, useState } from 'react';
import { DynamicIcon, IndicatorValue } from './';

interface IndicatorProps extends IndicatorsPrimaryProps {
  value: number | string;
}

interface IndicatorsPrimaryProps {
  name: string;
  unit: string;
}
/**
 * BATTERY AND SIGNAL LEVELS INDICATORS.
 * Display indicator title, icon, value and alert icon.
 */

export const IndicatorsPrimary: React.FunctionComponent = () => {
  const { state } = useAppState();
  const { panelData, dico } = state;
  const [indicators, setIndicators] = useState([] as IndicatorProps[]);

  /** Translation*/
  const TR_BATTERY = dico.battery;
  const TR_SIGNAL_LEVEL = dico['signal-level'];
  const TR_NO_DATA = dico['no-data'];

  const PRIMARY_INDICATORS = [
    { name: TR_BATTERY, unit: 'V' },
    { name: TR_SIGNAL_LEVEL, unit: 'dBm' },
  ];

  useEffect(() => {
    const getIndicators = PRIMARY_INDICATORS.map((indicator) => ({
      name: indicator.name,
      value: getValue(indicator.name, panelData, TR_NO_DATA, 2),
      unit: indicator.unit,
    }));
    setIndicators(getIndicators);
  }, [panelData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {indicators.length !== 0 &&
        indicators.map((indicator, index) => <Indicator {...indicator} key={`${indicator.name}-${index}`} />)}
    </>
  );
};

const Indicator: React.FunctionComponent<IndicatorProps> = ({ name, value, unit }) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.communPropBox}>
      <div className={styles.indicatorTitle}>
        <AlertState name={name} /> {name && name?.toUpperCase()}
      </div>
      <DynamicIcon value={value} name={name} />
      <IndicatorValue value={value} unit={unit} />
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    indicatorTitle: css`
      font-weight: 500;
      text-overflow: ellipsis;
      overflow: auto;
      font-size: 14px;
    `,
    communPropBox: css`
      border-radius: ${MAIN_BORDER_RADIUS}px;
      border: 1px solid ${theme.isDark ? theme.colors.border.weak : theme.colors.border.medium};
      margin: 5px;
      padding: 10px;
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      width: 50%;
    `,
  };
};
