import React from 'react';
import { useAppState } from 'app-context/AppStateContext';
import { getLastKnownState } from 'helpers/panel-data';
import { useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

interface ValveLastKnownStateProps {}

export const ValveLastKnownState: React.FunctionComponent<ValveLastKnownStateProps> = () => {
  const { state } = useAppState();
  const { panelData, theme, dico } = state;

  const styles = useStyles2(getStyles);

  const lastKnownState = React.useMemo(() => getLastKnownState(panelData, dico), [panelData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.wrapper}>
      <div className={styles.sectionTitle}>{dico['last-known-state']?.toUpperCase()}</div>
      <div
        style={{
          fontSize: '32px',
          color: `${lastKnownState.color ? lastKnownState.color : theme.colors.text.secondary}`,
          fontWeight: 'bolder',
        }}
      >
        {lastKnownState && lastKnownState.state}
      </div>
      {lastKnownState.img && (
        <div>
          <img src={lastKnownState.img} height={25} />
        </div>
      )}
      <div>{lastKnownState && lastKnownState.stateTime}</div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      display: flex;
      width: 100%;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      align-items: center;
    `,
    sectionTitle: css`
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 8px;
      color: ${theme.colors.text.primary};
    `,
  };
};
