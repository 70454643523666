import { GrafanaTheme2 } from '@grafana/data';
import { Icon, IconName, useStyles2 } from '@grafana/ui';
import { MAIN_BORDER_RADIUS, ThresholdColors, VERY_LIGHT_GREY } from 'constants/colors';
import { css } from '@emotion/css';
import * as React from 'react';

/**
 * Simple title (for light background)
 */

interface TitleProps {
  value: string;
  isLoading?: boolean;
}

export const Title: React.FunctionComponent<TitleProps> = ({ value, isLoading }) => {
  const styles = useStyles2(getStyles);

  return <div className={styles.title}>{value}</div>;
};

/**
 * Simple title (for dark background)
 */

export const TitleLight: React.FunctionComponent<TitleProps> = ({ value }) => {
  const styles = useStyles2(getStyles);
  return <div className={styles.titleLight}>{value}</div>;
};

/**
 * Frame with title
 */

interface FrameProps {
  title: string;
  children: JSX.Element[] | JSX.Element;
}

export const FrameWithTitle: React.FunctionComponent<FrameProps> = (props) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.frameContainer}>
      <div className={styles.titleContainer}>
        <Title value={props.title} />
      </div>
      {props.children}
    </div>
  );
};

/**
 * Validation Button (green)
 */

interface ValidationButtonProps {
  onClickAction: () => void;
  isDisabled?: boolean;
  buttonText: string;
}

export const ValidationButton: React.FunctionComponent<ValidationButtonProps> = ({
  onClickAction,
  isDisabled,
  buttonText,
}) => {
  const styles = useStyles2(getStyles);

  return (
    <button className={styles.button} onClick={onClickAction} disabled={isDisabled}>
      {buttonText}
    </button>
  );
};

/**
 * Secondary Button with icon (grey)
 */

interface SecondaryButtonProps {
  onClickAction: () => void;
  isDisabled?: boolean;
  buttonText: string;
  iconName: IconName;
}

export const SecondaryButton: React.FunctionComponent<SecondaryButtonProps> = ({
  onClickAction,
  isDisabled,
  buttonText,
  iconName,
}) => {
  const styles = useStyles2(getStyles);
  return (
    <button className={styles.cancelButton} onClick={onClickAction} disabled={isDisabled}>
      <Icon name={iconName} size="lg" /> {buttonText}
    </button>
  );
};

interface DisplayErrorProps {
  errorText: string;
}

export const DisplayError: React.FunctionComponent<DisplayErrorProps> = ({ errorText }) => {
  return <div style={{ color: 'red' }}>{errorText}</div>;
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    title: css`
      font-size: 15px;
      font-weight: 600;
      color: ${theme.colors.text.primary};
    `,
    titleLight: css`
      font-size: 15px;
      font-weight: 600;
      color: ${theme.isDark ? '#292a2d' : '#e9edf2'};
    `,
    frameContainer: css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: start;
      border: 5px solid ${theme.isDark ? theme.colors.border.weak : theme.colors.border.medium};
      padding: 8px;
      min-height: 320px;
      height: 100%;
    `,
    titleContainer: css`
      width: 100%;
      margin-bottom: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid ${theme.isDark ? theme.colors.border.weak : theme.colors.border.medium};
    `,
    button: css`
      background: ${ThresholdColors.OK};
      border: none;
      color: ${VERY_LIGHT_GREY};
      font-size: calc(11px + 0.25vw);
      font-weight: 500;
      border-radius: ${MAIN_BORDER_RADIUS}px;
      cursor: pointer;
      vertical-align: middle;
      padding: 3px 15px;
      &:hover {
        background: #2ccc98;
      }
      &:disabled {
        background: #d3f0e7dd;
        color: #add4c8;
        cursor: not-allowed;
      }
    `,
    cancelButton: css`
      background: ${theme.colors.background.secondary};
      border: none;
      color: ${theme.colors.text.primary};
      font-size: calc(11px + 0.25vw);
      font-weight: 500;
      border-radius: ${MAIN_BORDER_RADIUS}px;
      cursor: pointer;
      vertical-align: middle;
      padding: 3px 15px;
      &:hover {
        background: ${theme.colors.background.canvas};
      }
      &:disabled {
        background: ${theme.colors.background.canvas};
        color: ${theme.colors.text.primary};
        cursor: not-allowed;
      }
    `,
  };
};
