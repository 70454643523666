import React from 'react';
import { useAppState } from 'app-context/AppStateContext';
import { GrafanaTheme2, dateTimeFormat } from '@grafana/data';
import { IconName, Icon, Button, useStyles2 } from '@grafana/ui';
import { ThresholdColors } from 'constants/colors';
import { css } from '@emotion/css';
import { getBackendSrv } from '@grafana/runtime';

interface LastClosedStateProps {}

export const ValveAlert: React.FunctionComponent<LastClosedStateProps> = () => {
  const { state } = useAppState();
  const { alerts, user, dico } = state;
  const styles = useStyles2(getStyles);

  const [forParameter, setForParameter] = React.useState(0);

  const goToPanel = user.orgRole !== 'Viewer' ? 'editPanel' : 'viewPanel';
  const onOffAlertName = dico['open-closed-state']?.toUpperCase();

  const getOnOffAlertStatus = () => {
    let alertProperties = {
      id: 0,
      name: '',
      panelId: null,
      state: '',
      url: '',
      linkToAlert: '',
      date: '',
      for: 0,
    };

    if (alerts.length) {
      const alertIndex = alerts.findIndex((alert) => alert.name?.toUpperCase() === onOffAlertName?.toUpperCase());
      if (alertIndex !== -1) {
        const alertValveStatus = alerts[alertIndex];
        alertProperties.id = alertValveStatus.id;
        alertProperties.name = alertValveStatus.name;
        alertProperties.panelId = alertValveStatus.panelId;
        alertProperties.state = alertValveStatus.state;
        alertProperties.url = alertValveStatus.url;
        alertProperties.linkToAlert = `${alertValveStatus.url}?tab=alert&${goToPanel}=${alertValveStatus.panelId}`;
        alertProperties.date = dateTimeFormat(alertValveStatus.newStateDate);
      }
    }
    return alertProperties;
  };

  const getAlertVisuals = (status: string) => {
    let alertVisuals = { color: '', icon: '' as IconName, text: '', alternativeText: '' };

    switch (status) {
      case 'alerting':
        alertVisuals.color = ThresholdColors.DANGER;
        alertVisuals.icon = 'exclamation-triangle';
        alertVisuals.text = dico['alert-alerting-title'];
        alertVisuals.alternativeText = dico['alert-alerting-text'];
        break;

      case 'no_data':
        alertVisuals.color = ThresholdColors.MEDIUM;
        alertVisuals.icon = 'question-circle';
        alertVisuals.text = dico['alert-nodata-title'];
        alertVisuals.alternativeText = dico['alert-nodata-text'];
        break;

      case 'ok':
        alertVisuals.color = ThresholdColors.OK;
        alertVisuals.icon = 'check-circle';
        alertVisuals.text = dico['alert-ok-title'];
        alertVisuals.alternativeText = dico['alert-ok-text'];
        break;

      case 'pending':
        alertVisuals.color = ThresholdColors.NONE;
        alertVisuals.icon = 'check-circle';
        alertVisuals.text = dico['alert-nodata-title'];
        alertVisuals.alternativeText = dico['alert-pending-text'];
        break;

      case 'paused':
        alertVisuals.color = ThresholdColors.NONE;
        alertVisuals.icon = 'pause';
        alertVisuals.text = dico['alert-nodata-title'];
        alertVisuals.alternativeText = dico['alert-paused-text'];
        break;

      default:
        alertVisuals.color = ThresholdColors.NONE;
        alertVisuals.icon = 'question-circle';
        alertVisuals.text = dico['alert-nodata-title'];
        alertVisuals.alternativeText = dico['alert-unknown-text'];
        break;
    }
    return alertVisuals;
  };

  const alertStatus = React.useMemo(() => getOnOffAlertStatus(), [alerts]); // eslint-disable-line react-hooks/exhaustive-deps
  const alertDisplay = React.useMemo(() => getAlertVisuals(alertStatus?.state), [alertStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (alertStatus?.state === 'alerting' && alertStatus?.id !== 0) {
      const getAlertParameters = async () => {
        const alertParameters = await getBackendSrv()
          .get(`api/alerts/${alertStatus.id}`)
          .catch((err: any) => console.log(err));

        if (alertParameters?.data && alertParameters.data.For) {
          const hours = alertParameters.data.For / 3600000000000; // convert ns to hours
          setForParameter(hours);
        }
      };
      getAlertParameters();
    }
  }, [alertStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.alertContainer}>
      <div
        className={styles.alertBox}
        style={{ backgroundColor: `${alertDisplay.color}1a`, color: alertDisplay.color }}
      >
        <div style={{ marginRight: '8px' }}>
          <Icon name={alertDisplay.icon} size={'xl'} />
        </div>
        <div>{alertDisplay.text?.toUpperCase()}</div>
      </div>
      <div className={styles.alternativeText}>
        <div style={{ color: alertDisplay.color }}>
          {alertDisplay.alternativeText}
          {alertStatus.state === 'alerting' && <span style={{ fontWeight: 600 }}> {forParameter}h.</span>}
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {alertStatus.state !== 'ok' && (
          <div style={{ fontSize: '12px' }}>
            {dico['alert-newState']}: {alertStatus.date}
          </div>
        )}
        <Button size={'sm'} variant={'secondary'} icon={'bell'} onClick={() => window.open(alertStatus.linkToAlert)}>
          {dico['alert-settings']}
        </Button>
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    alertContainer: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
    `,
    alertBox: css`
      display: flex;
      font-size: 20px;
      font-weight: 600;
      padding: 4px;
      border-radius: 4px;
    `,
    alternativeText: css`
      font-size: 18px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
    `,
  };
};
