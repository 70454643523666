import { useAppState } from 'app-context/AppStateContext';
import { ThresholdsBattery, ThresholdsRadio } from 'constants/thresholds';
import React, { useEffect, useState } from 'react';

const RadioGoodQualityIcon = require('../../img/radio-very-good.svg') as string;
const RadioMediumQualityIcon = require('../../img/radio_medium_quality.svg') as string;
const RadioBadQualityIcon = require('../../img/radio_bad_quality.svg') as string;
const RadioVeryBadQualityIcon = require('../../img/radio_very_bad_quality.svg') as string;
const RadioNoValueIcon = require('../../img/radio_no_signal.svg') as string;
const BatteryGoodLevelIcon = require('../../img/battery_good.svg') as string;
const BatteryMediumLevelIcon = require('../../img/battery_medium.svg') as string;
const BatteryLowLevelIcon = require('../../img/battery_low.svg') as string;
const BatteryVeryLowLevelIcon = require('../../img/battery_very_low.svg') as string;
const BatteryNoValueIcon = require('../../img/battery_no_value.svg') as string;
const BatteryEmptyIcon = require('../../img/battery_empty.svg') as string;

export interface DynamicIconProps {
  value: number | string;
  name: string;
}

export const DynamicIcon: React.FunctionComponent<DynamicIconProps> = ({ value, name }) => {
  const { state } = useAppState();
  const { dico } = state;
  const [svgSrc, setSvgSrc] = useState('');

  const getIcon = () => {
    if (typeof value === 'string' && value !== dico['no-data']) {
      // Handle radio icon
      if (name === dico['signal-level']) {
        const dynamicValue = parseInt(value, 10);
        switch (true) {
          case dynamicValue >= ThresholdsRadio.GOOD:
            setSvgSrc(RadioGoodQualityIcon);
            break;
          case dynamicValue < ThresholdsRadio.GOOD && dynamicValue >= ThresholdsRadio.MEDIUM:
            setSvgSrc(RadioMediumQualityIcon);
            break;
          case dynamicValue < ThresholdsRadio.MEDIUM && dynamicValue >= ThresholdsRadio.LOW:
            setSvgSrc(RadioBadQualityIcon);
            break;
          case dynamicValue < ThresholdsRadio.LOW:
            setSvgSrc(RadioVeryBadQualityIcon);
            break;
          default:
            setSvgSrc(RadioNoValueIcon);
            break;
        }
      }
      // Handle battery icon
      else if (name === dico.battery) {
        const batteryValue = parseFloat(value);
        switch (true) {
          case batteryValue >= ThresholdsBattery.GOOD:
            setSvgSrc(BatteryGoodLevelIcon);
            break;
          case batteryValue < ThresholdsBattery.GOOD && batteryValue > ThresholdsBattery.MEDIUM:
            setSvgSrc(BatteryMediumLevelIcon);
            break;
          case batteryValue <= ThresholdsBattery.MEDIUM && batteryValue >= ThresholdsBattery.LOW:
            setSvgSrc(BatteryLowLevelIcon);
            break;
          case batteryValue < ThresholdsBattery.LOW && batteryValue >= ThresholdsBattery.VERYLOW:
            setSvgSrc(BatteryVeryLowLevelIcon);
            break;
          case batteryValue < ThresholdsBattery.VERYLOW:
            setSvgSrc(BatteryEmptyIcon);
            break;
          default:
            setSvgSrc(BatteryNoValueIcon);
            break;
        }
      }
    } else {
      // Handle no value
      switch (name) {
        case dico.battery:
          setSvgSrc(BatteryNoValueIcon);
          break;

        case dico['signal-level']:
          setSvgSrc(RadioNoValueIcon);
          break;
      }
    }
  };

  useEffect(() => {
    if (value) {
      getIcon();
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <img src={svgSrc} height={30} />
    </div>
  );
};
