import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2, Tooltip } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { MIDDLE_GREY } from 'constants/colors';
import { css } from '@emotion/css';
import { getColorSessionStatus } from 'helpers/thresholds';
import React, { useEffect, useState } from 'react';
import { getSessionQuery } from 'services/queries';
import { mysqlRequest } from 'services/requests';
import { Title } from './';

const defaultSvg = (
  <svg height="20" width="20">
    <circle cx="10" cy="10" r="10" fill={'gray'} />
  </svg>
);

export const TrainingStatus: React.FunctionComponent = () => {
  const { state, dispatch } = useAppState();
  const { dico, currentSession, macAddress, datasourceMysql, panelData } = state;
  const [svgStatus, setSvgStatus] = useState(defaultSvg);
  const [idSession, setIdSession] = useState(0);
  const styles = useStyles2(getStyles);

  /**
   * translation
   */

  const TR_CURRENT_SESSION = dico['current-session']?.toUpperCase();
  const TR_NO_SESSION = dico['current-session-no'];

  const getSessionStatus = async () => {
    setIdSession(currentSession.id);
    const svg = getSvgStatus(currentSession.status);
    setSvgStatus(svg);
  };

  const getSvgStatus = (status: string) => {
    const fillColor = getColorSessionStatus(status);
    return (
      <Tooltip content={`Status : ${status}`}>
        <svg height="20" width="20">
          <circle cx="10" cy="10" r="10" fill={fillColor} />
        </svg>
      </Tooltip>
    );
  };

  const getCurrentSession = async () => {
    const currentAiSessionQuery = getSessionQuery(macAddress);
    const queryResult = await mysqlRequest(datasourceMysql.uid, currentAiSessionQuery);
    if (!queryResult.length) {
      return;
    }

    const result = queryResult[0];
    dispatch({ type: 'SET_CURRENT_SESSION', payload: { id: result[0], status: result[1] } });
  };

  useEffect(() => {
    if (datasourceMysql.uid) {
      getCurrentSession();
    }
  }, [datasourceMysql, panelData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getSessionStatus();
  }, [currentSession]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperContent}>
        {idSession !== 0 ? (
          <>
            <Title value={`${TR_CURRENT_SESSION} (${dico?.last?.toUpperCase()})`} />
            <div className={styles.svgContainer}>{svgStatus}</div>{' '}
          </>
        ) : (
          TR_NO_SESSION
        )}
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
    `,
    wrapperContent: css`
      display: flex;
      width: 100%auto;
      align-items: center;
      justify-content: space-between;
      background: ${theme.isDark ? theme.colors.border.weak : theme.colors.border.medium};
      padding: 8px;
      color: ${theme.colors.text.primary};
    `,
    svgContainer: css`
      display: flex;
      align-items: center;
    `,
    tinyText: css`
      font-size: 10px;
      letter-spacing: 2px;
      font-weight: 600;
      padding: 2px 4px;
      color: ${MIDDLE_GREY};
    `,
  };
};
