import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { MIDDLE_GREY } from 'constants/colors';
import { css } from '@emotion/css';
import { rotationSpeedGraph, shockIndicatorGraph, vibrationSeverityGraph } from 'layouts/';
import * as React from 'react';
import { InstantIndicator, LoadingIndicators } from './';
import { applyThemeToGraph } from 'helpers/graphs';

interface InstantIndicatorsContainerProps {}

export const InstantIndicatorsContainer: React.FunctionComponent<InstantIndicatorsContainerProps> = () => {
  const { state } = useAppState();
  const { dico, isDicoLoading, theme, panelOptions, lengthUnit } = state;
  const { showRpmGauge, rpmCritical, rpmDanger, shockCritical, shockDanger, rpmLimit, vibrationLimit } = panelOptions;
  const styles = useStyles2(getStyles);

  applyThemeToGraph(vibrationSeverityGraph, theme);
  applyThemeToGraph(rotationSpeedGraph, theme);
  applyThemeToGraph(shockIndicatorGraph, theme);

  /** The title given to the indicator has to be the same as
   * the alias set into the query */

  const INSTANT_INDICATORS = [
    {
      title: dico['vibration-severity']?.toUpperCase(),
      unit: lengthUnit === 'inch' ? 'in/s' : 'mm/s',
      layout: vibrationSeverityGraph,
      isDisplayed: true,
      thresholds: [], // will be defined by iso thresholds or user's config
      max: lengthUnit === 'inch' ? 1 : vibrationLimit,
      decimals: 2,
    },
    {
      title: dico['rotation-speed']?.toUpperCase(),
      unit: 'rpm',
      layout: rotationSpeedGraph,
      isDisplayed: showRpmGauge,
      thresholds: [rpmCritical, rpmDanger],
      max: rpmLimit,
      decimals: 0,
    },
    {
      title: dico['shock-indicator']?.toUpperCase(),
      unit: '',
      layout: shockIndicatorGraph,
      isDisplayed: !showRpmGauge,
      thresholds: [shockCritical, shockDanger],
      max: 100,
      decimals: 2,
    },
  ];

  return (
    <div className={styles.bottomLeftSections}>
      {/* Gauges / Graph - Vibratory Severity & Shock Indicaot || Rotation Speed*/}
      {isDicoLoading
        ? INSTANT_INDICATORS.map((indicator, index) => <LoadingIndicators key={`${indicator.title}-${index}`} />)
        : INSTANT_INDICATORS.map(
            ({ decimals, isDisplayed, layout, max, thresholds, title, unit }, index) =>
              isDisplayed && (
                <InstantIndicator
                  title={title}
                  unit={unit}
                  layout={layout}
                  thresholds={thresholds}
                  max={max}
                  decimals={decimals}
                  key={`${title}-${index}`}
                />
              )
          )}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    bottomLeftSections: css`
      display: flex;
      flex-direction: column;
      width: 20%;
      background: ${theme.isDark ? '#b3b3b3' : MIDDLE_GREY};
      min-width: 235px;
    `,
  };
};
