import { MIDDLE_GREY } from 'constants/colors';

export let vibrationSeverityGraph = {
  height: 204,
  margin: {
    l: 30,
    b: 65,
    t: 30,
    r: 10,
  },
  xaxis: {
    type: 'date',
    autorange: true,
    range: [] as any,
    color: '',
    gridcolor: '',
    zeroline: false,
    tickfont: {
      size: 10,
    },
    ticks: 'outside',
    tickcolor: MIDDLE_GREY,
  },
  paper_bgcolor: 'transparent',
  plot_bgcolor: 'transparent',
  yaxis: {
    range: [0, 0],
    zerolinecolor: '',
    tickfont: {
      size: 11,
    },
    color: '',
    gridcolor: '',
  },
  shapes: [] as any,
  annotations: [] as any,
  uirevision: 'true' as any,
};
