import { GrafanaTheme2 } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { Modal, useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { NotificationError } from 'components/shared';
import { MAIN_BORDER_RADIUS } from 'constants/colors';
import { css, keyframes } from '@emotion/css';
import React, { useLayoutEffect, useState } from 'react';
import { JSON_DATASOURCE_NAME, URL_GET_DATASOURCE_BY_NAME } from 'constants/api';

export interface MachinePhotoProps {}

export const IdentityCardPhoto: React.FunctionComponent<MachinePhotoProps> = () => {
  const { state } = useAppState();
  const { datasourceJson, fileImageName, isDicoLoading, dashboard } = state;
  const [imageSrc, setImageSrc] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const styles = useStyles2((theme) => getStyles(imageSrc, theme));
  const defaultImage = 'public/asystom/asystom-no-image-preview.png';

  const queryImage = async () => {
    if (!fileImageName) {
      setImageSrc(defaultImage);
      return;
    }
    const dsJson: any = await getBackendSrv().get(`${URL_GET_DATASOURCE_BY_NAME}${JSON_DATASOURCE_NAME}`);

    const queryResult = await getBackendSrv()
      .get(`api/datasources/proxy/uid/${dsJson.uid}/downloadImage?fileName=` + fileImageName)
      .catch((err) => {
        setImageSrc(defaultImage);
        NotificationError({ status: err?.status, message: 'Image not found' });
      });

    if (!queryResult) {
      setImageSrc(defaultImage);
      return;
    }
    setImageSrc(queryResult);
  };

  useLayoutEffect(() => {
    if (datasourceJson.length !== 0) {
      queryImage();
    }
  }, [datasourceJson]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {isDicoLoading || !imageSrc ? (
        <div
          className={css`
            animation: ${styles.element} 2s ease infinite;
          `}
          style={{ flex: 1.2 }}
        >
          <div className={styles.photoPlaceholder} />
        </div>
      ) : (
        <div className={styles.photo} onClick={() => setIsModalOpen(true)}></div>
      )}
      <Modal isOpen={isModalOpen} title={`${dashboard.name} - photo`} onDismiss={() => setIsModalOpen(false)}>
        <div
          className={css`
            display: flex;
            justify-content: center;
          `}
        >
          <img src={imageSrc} />
        </div>
      </Modal>
    </>
  );
};

const getStyles = (imageSrc: string, theme: GrafanaTheme2) => {
  return {
    photo: css`
      flex: 1.2;
      background-image: url(${imageSrc});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 15px;
      border-radius: ${MAIN_BORDER_RADIUS}px;
      cursor: pointer;
    `,
    photoPlaceholder: css`
      flex: 1.2;
      height: 100%;
      margin-right: 15px;
      border-radius: ${MAIN_BORDER_RADIUS}px;
      background: ${theme.colors.background.secondary};
      opacity: 0.7;
    `,
    element: keyframes`
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.4;
        }
        100% {
          opacity: 1;
        } 
        `,
  };
};
