import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { MAIN_BORDER_RADIUS, MIDDLE_GREY, VERY_LIGHT_GREY } from 'constants/colors';
import { css, keyframes } from '@emotion/css';
import React from 'react';
import { IdentityCard, IdentityCardPhoto, IndicatorsPrimary, IndicatorsSecondary } from './';

export interface TopSectionContainerProps {}

export const HeaderContainer: React.FunctionComponent<TopSectionContainerProps> = () => {
  const { state } = useAppState();
  const { panelOptions, isDicoLoading } = state;
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.wrapper}>
      {/* Photo - left pane */}
      <IdentityCardPhoto />
      {/* Machine informations - middle pane */}
      <div className={styles.idWrapper}>
        <IdentityCard />
      </div>
      {/* Battery and Signal - right pane */}
      {isDicoLoading ? (
        <div
          className={css`
            animation: ${styles.element} 2s ease infinite;
          `}
        >
          <div className={styles.indicatorWrapperLoading} />
        </div>
      ) : (
        <div className={styles.indicatorWrapper}>
          <div className={styles.beaconIndicators}>
            <IndicatorsPrimary />
          </div>
          {/* Ambiant temperature and humidity */}
          {(panelOptions.showHumidity || panelOptions.showTemperature) && (
            <div className={styles.ambiantIndicators}>
              <IndicatorsSecondary />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      display: flex;
      flex-direction: row;
      height: 185px;
      padding: 15px;
      background: ${theme.isDark ? '#b3b3b3' : MIDDLE_GREY};
    `,
    idWrapper: css`
      display: flex;
      flex-direction: column;
      flex: 4;
    `,
    indicatorWrapper: css`
      display: flex;
      flex-direction: row;
      margin: 3px;
      flex: 2;
      border-radius: ${MAIN_BORDER_RADIUS}px;
      background: ${theme.isDark ? '#222426' : '#fbfbfb'};
    `,
    beaconIndicators: css`
      display: flex;
      flex-direction: row;
      height: 100%;
      flex: 3;
    `,
    ambiantIndicators: css`
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
    `,
    indicatorWrapperLoading: css`
      display: flex;
      min-width: 398px;
      height: 149px;
      flex-direction: row;
      margin: 3px;
      flex: 2;
      border-radius: ${MAIN_BORDER_RADIUS}px;
      background: ${VERY_LIGHT_GREY};
      opacity: 0.7;
    `,
    element: keyframes`
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.4;
      }
      100% {
        opacity: 1;
      } 
      `,
  };
};
