export let acousticEnergyLayout = {
  height: 210,
  plot_bgcolor: 'transparent',
  paper_bgcolor: 'transparent',
  margin: {
    l: 40,
    b: 25,
    t: 5,
    r: 5,
  },
  xaxis: {
    type: 'date',
    zeroline: true,
    autorange: true,
    range: [] as any,
    fixedrange: true, // disables zoom
  },
  yaxis: {
    anchor: 'x',
    autorange: true,
    color: '',
    fixedrange: true, // disables zoom
    zeroline: true,
    zerolinecolor: '#bababa',
    title: {
      text: 'dB',
    },
    domain: [0.25, 1],
  },
  yaxis2: {
    anchor: 'x',
    range: [0, 1],
    color: '',
    fixedrange: true,
    zeroline: false,
    title: {
      text: '',
    },
    ticks: '',
    domain: [0, 0.15],
    showgrid: false,
    showticklabels: false,
    hoverinfo: 'skip',
  },
  shapes: [] as any,
  annotations: [],
  uirevision: 'true' as any,
  hoverlabel: {
    bgcolor: '#FFF',
    namelength: -1,
    font: {
      color: '',
    },
  },
  hovermode: 'x unified',
  showlegend: false,
  grid: { rows: 2, columns: 1, pattern: 'coupled' },
};
