import { MIDDLE_GREY, PRIMARY_COLOR_TEXT } from 'constants/colors';

export let trainingZoneSelectionGraph = {
  height: 400,
  paper_bgcolor: 'transparent',
  plot_bgcolor: 'transparent',
  title: {
    text: '',
    font: {
      family: 'Courier New, monospace',
      size: 12,
      color: '',
    },
    margin: {
      l: 30,
      b: 30,
      t: 30,
      r: 10,
    },
  },
  dragmode: 'select',
  selectdirection: 'h',
  xaxis: {
    type: 'date',
    autorange: true,
    range: [] as any,
    tickcolor: MIDDLE_GREY,
    ticks: 'outside',
    zeroline: false,
    color: '',
    gridcolor: '',
  },
  yaxis: {
    autorange: true,
    fixedrange: 'true',
    zerolinecolor: PRIMARY_COLOR_TEXT,
    color: '',
    gridcolor: '',
  },
  shapes: [] as any,
  annotations: [] as any,
  uirevision: 'true' as any,
};
