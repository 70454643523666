export const MYSQL_DATASOURCE_NAME = 'mysql_datasource';
export const INFLUX_DATASOURCE_NAME = 'asystom_db';
export const JSON_DATASOURCE_NAME = 'DashGen';

export const API_MYSQL_REQUEST = 'api/ds/query';
export const API_DASHBOARD_UID = 'api/dashboards/uid/';
export const API_ALERTS_DASHBOARD_ID = 'api/alerts?dashboardId=';
export const URL_GET_DATASOURCE_BY_NAME = 'api/datasources/name/';

export const DICTIONARY_URL = '/public/asystom/asystom-dictionaries/incident-advisor/';
export const ISO_CLASS_URL = '/public/asystom/iso-classifications/classifications.json';
export const STRATEGIES_URL = '/public/asystom/asystom-dictionaries/advisor_strategies_';
