import { MIDDLE_GREY, PRIMARY_COLOR_TEXT } from 'constants/colors';

export let ultrasoundGraph = {
  title: {
    text: '',
    font: {
      size: 12,
      color: '',
    },
    pad: {
      b: 3,
    },
    yanchor: 'bottom',
    y: 0,
  },
  height: 200,
  paper_bgcolor: 'transparent',
  plot_bgcolor: 'transparent',
  margin: {
    l: 40,
    b: 40,
    t: 30,
    r: 50,
  },
  xaxis: {
    autorange: true,
    color: '',
    fixedrange: false, // disables zoom
    zerolinecolor: PRIMARY_COLOR_TEXT,
    gridcolor: '',
  },
  yaxis: {
    gridcolor: '',
    autorange: true,
    color: '',
    zeroline: false,
    fixedrange: false, // disables zoom
    ticks: 'outside',
    tickcolor: MIDDLE_GREY,
    tickfont: {
      size: 11,
    },
  },
  shapes: [] as any,
  annotations: [] as any,
  uirevision: 'true' as any,
  hovermode: 'x unified',
  hoverlabel: {
    bgcolor: 'white',
    bordercolor: 'white',
  },
  showlegend: false,
};
