import { AppState, Action } from './types';

/**
 * Contains all the state actions
 */

export const appStateReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case 'GET_PANEL_DATA': {
      return {
        ...state,
        panelData: action.payload,
      };
    }

    case 'GET_TIME_RANGE': {
      return {
        ...state,
        timeRangePanel: action.payload,
      };
    }

    case 'SET_TIMEZONE': {
      return {
        ...state,
        timeZone: action.payload,
      };
    }

    case 'SET_TEMPERATURE_UNIT': {
      return {
        ...state,
        temperatureUnit: action.payload,
      };
    }

    case 'GET_DASHBOARD_INFOS': {
      return {
        ...state,
        dashboard: {
          name: action.payload.name,
          id: action.payload.id,
          tags: action.payload.tags,
        },
      };
    }

    case 'SET_CURRENT_SESSION': {
      return {
        ...state,
        currentSession: action.payload,
      };
    }

    case 'SET_SELECTED_SESSION': {
      return {
        ...state,
        selectedSession: action.payload,
      };
    }

    case 'SET_SELECTED_ZONE': {
      return {
        ...state,
        userTrainingZones: action.payload,
      };
    }

    case 'SET_SELECTED_POINT_DATE': {
      return {
        ...state,
        selectedPointDate: action.payload,
      };
    }

    case 'SET_DICO_LOADING': {
      return {
        ...state,
        isDicoLoading: action.payload,
      };
    }

    case 'SET_TOTAL_POINTS': {
      return {
        ...state,
        totalSelectedPoints: action.payload,
      };
    }

    case 'GET_ALERTS': {
      return {
        ...state,
        alerts: action.payload,
      };
    }

    case 'SET_DATASOURCES': {
      return {
        ...state,
        datasourceMysql: action.payload.mysql,
        datasourceInflux: action.payload.influx,
        datasourceJson: action.payload.dashGen,
      };
    }

    case 'GET_DICO': {
      return {
        ...state,
        dico: action.payload,
      };
    }

    case 'SET_IDENTITY_CARD': {
      return {
        ...state,
        identityCard: action.payload,
      };
    }

    case 'SET_PANEL_OPTIONS': {
      return {
        ...state,
        panelOptions: action.payload,
      };
    }

    case 'SET_ANALYSIS_PROFILE': {
      return {
        ...state,
        analysisProfile: action.payload,
      };
    }

    case 'SET_STATISTICS_QUERY': {
      return {
        ...state,
        statisticsQuery: action.payload,
      };
    }

    case 'SET_ISO_CLASSIFICATIONS': {
      return {
        ...state,
        isoClassifications: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
